import React, { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import NavigationBar from "./Navbar/NavigationBar";
import Footer from "./Footer/Footer";
import {
  HEADER_DEFAULT,
  HEADER_EXTERNAL,
  HEADER_NONE,
  FOOTER_DEFAULT,
  FOOTER_EXTERNAL,
  FOOTER_NONE,
} from "../utils/constanta";
import NavbarExternal from "./Navbar/NavbarExternal";
import ButtonWhatsapp from "./ButtonWhatsapp/ButtonWhatsapp";
import FooterExternal from "./Footer/FooterExternal";
import { inIframe } from "../utils/helpers";
import {
  PENGKINIAN_DATA_PATH,
  UPLOAD_KUITANSI_PATH,
  DIRECT_DEBIT_PATH,
} from "src/constants";

//refer to constanta
const HeaderModel = [
  {
    type: HEADER_DEFAULT,
    component: <NavigationBar />,
  },
  {
    type: HEADER_EXTERNAL,
    component: <NavbarExternal />,
  },
  {
    type: HEADER_NONE,
    component: <> </>,
  },
];

const FooterModel = [
  {
    type: FOOTER_DEFAULT,
    component: <Footer />,
  },
  {
    type: FOOTER_EXTERNAL,
    component: <FooterExternal />,
  },
  {
    type: FOOTER_NONE,
    component: <> </>,
  },
];

const HeaderFactory = (type) => {
  const header = HeaderModel.filter((obj) => {
    return obj.type === type;
  });

  return header[0] && header[0].component
    ? header[0].component
    : HeaderModel[0].component;
};

const FooterFactory = (type) => {
  const footer = FooterModel.filter((obj) => {
    return obj.type === type;
  });

  return footer[0] && footer[0].component
    ? footer[0].component
    : FooterModel[0].component;
};

type LayoutProps = {
  children?: any;
  error?: string;
  height?: string;
  mobileHeight?: string;
  headerTypeProps?: string;
  footerTypeProps?: string;
};

const Layout = ({
  children,
  error,
  height,
  headerTypeProps = HEADER_DEFAULT,
  footerTypeProps = FOOTER_DEFAULT,
  mobileHeight,
}: LayoutProps) => {
  const [headerType, setHeaderType] = useState(headerTypeProps);
  const [footerType, setFooterType] = useState(footerTypeProps);
  const [showLC, setShowLC] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (error) {
      router.push("/");
    }
  }, [error]);

  useEffect(() => {
    //cek is in iframe then apply header none

    if (inIframe()) {
      setHeaderType(HEADER_NONE);
      setFooterType(FOOTER_DEFAULT);
    }
  });

  useEffect(() => {
    //to set header and footer from props change (like in slik)
    if (headerType !== headerTypeProps) setHeaderType(headerTypeProps);
    if (footerType !== footerTypeProps) setHeaderType(footerTypeProps);
  }, [headerTypeProps, footerTypeProps]);

  const pathname = usePathname();
  const asPath = pathname;

  useEffect(() => {
    const allowedPaths = [
      PENGKINIAN_DATA_PATH,
      UPLOAD_KUITANSI_PATH,
      DIRECT_DEBIT_PATH,
    ];
    setShowLC(allowedPaths.includes(asPath));
  }, [showLC]);

  return (
    <React.Fragment>
      <noscript>
        <iframe
          src="//www.googletagmanager.com/ns.html?id=GTM-T97TWZD"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      {HeaderFactory(headerType)}
      <div className="main-container">{children}</div>
      {!showLC && <ButtonWhatsapp />}
      {FooterFactory(footerType)}
      <style jsx global>{`
        * {
          margin: 0;
          padding: 0;
        }
        h2 {
          margin-block-start: 0.83em;
          margin-block-end: 0.83em;
        }
        .main-container {
          display: flex;
          flex-direction: column;
          min-height: ${height ? height : "100vh"};
        }
        .cx-widget.cx-window-manager.cx-theme-light {
          display: ${showLC ? "auto" : "none !important"};
        }
        @media only screen and (max-width: 600px) {
          .main-container {
            min-height: ${mobileHeight ? mobileHeight : "100vh"};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default Layout;
